import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { darkred, FlexContainer, SiteContent, SiteWrapper, white } from '../components/styles'

const TEAM = gql`
{
    teammitglieder {
      vorname
      nachname
      strasse
      ort
      telefon
      eMail
      teamseite
    }
  }
`  

const Kontakt = () => {
    const {loading, error, data} = useQuery(TEAM)

    return (
        <Layout layoutImage="kontakt">
            <SiteContent>
            <FlexContainer direction="column">
            <SEO title="Kontakt" />
            <h2>
            Kontakt
            </h2>
            
            { data && data.teammitglieder.map((value, index) =>{
                if (value.teamseite === true) {
               return (
               <div key={index}>
                    <h4 css={{width: "100%", borderBottom: "1px solid " + darkred, paddingBottom: "0.2em"}}>{value.vorname} {value.nachname}</h4>
                    <div css={{paddingLeft: "2em"}}>
                        {value.telefon &&<p>Tel.: {value.telefon}</p>}
                        {value.eMail && <p>E-Mail: <a href={"mailto:" + value.eMail}>{value.eMail}</a></p>}
                        {value.strasse && <p>{value.strasse}, {value.ort} </p>}
                    </div>
                </div>
               )
                }
                return null
                
            })}
           
            </FlexContainer>

            </SiteContent>


        </Layout>
       

    )
}

export default Kontakt